.block.block-gallery {
    padding-top: 20*$base-width;
    padding-bottom: 20*$base-width;
    padding-left: 2.5*$base-width;
    padding-right: 2.5*$base-width;
    background-image: url("../../assets/img/layout/background-dark.svg");
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-top: 12*$base-width;
        padding-bottom: 12*$base-width; }
    .wrapper_galerie {
        margin: 0 auto;
        max-width: $boxed;
        position: relative;
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            max-width: 50rem; }
        .headline_vertical {
            left: -16.5rem;
            bottom: 11.4rem;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                left: -78px; }
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                left: -14rem;
                bottom: 8.4rem; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                display: none; } } }

    .gallery-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $primary;
        opacity: 0;
        transition: $transition;
        z-index: 9;
        align-items: center;
        justify-content: center;
        display: flex;

        svg {
            max-width: 8rem;
            width: 100%;
            max-height: 8rem;
            height: 100%;
            fill: $light;
            opacity: 1; } }

    .gallery-grid {
        display: flex;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex-wrap: wrap; }

        picture {
            position: relative;
            display: block;

            img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center; } } }

    .col-left, .col-center, .col-right {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        justify-content: center; }

    .col-left {
        flex: 0 0 33.3%;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex: 0 0 100%;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            column-gap: 3rem; } }

    .col-right {
        flex: 0 0 24%;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex: 0 0 100%;
            column-gap: 3rem; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            row-gap: 3rem; } }


    .col-left, .col-center {
        margin-right: 3rem;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            margin-right: 0; } }

    .col-center {
        flex: 0 0 39%;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex: 0 0 100%;
            margin-bottom: 3rem; } }

    .pic-0, .pic-1, .pic-2, .pic-3, .pic-4 {
        position: relative;
        &:hover {
            .gallery-overlay {
                opacity: 0.88;
                transition: $transition; } } }

    .pic-0, .pic-1 {
        max-width: 53.2rem;
        width: 100%;
        max-height: 40rem;
        height: 100%;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex: 0 0 40%; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            flex: 0 0 100%; }

        picture {
            padding-top: 40rem; } }

    .pic-0 {
        margin-bottom: 3rem; }


    .pic-2 {
        max-width: 62.4rem;
        width: 100%;
        max-height: 83rem;
        height: 100%;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            max-width: 82.5%; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            display: none; }
        picture {
            padding-top: 83rem;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding-top: 45rem; } } }



    .pic-3 {
        max-width: 38.4rem;
        width: 100%;
        max-height: 51rem;
        height: 100%;
        margin-bottom: 3rem;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            margin-bottom: 0;
            max-width: 53.2rem;
            max-height: 29rem; }
        picture {
            padding-top: 51rem;
            img {
                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    max-height: 29rem; } } } }


    .pic-4 {
        max-width: 38.4rem;
        width: 100%;
        max-height: 29rem;
        height: 100%;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            max-width: 53.2rem; }
        picture {
            padding-top: 29rem; } }

    .pic-3, .pic-4 {
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex: 0 0 40%; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            flex: 0 0 100%; } }

    .pic-0, .pic-1, .pic-2, .pic-3, .pic-4 {
        opacity: 0; }

    //ANIMATION
    .in-view {

        .pic-0 {
            animation: slide-in-img 200ms ease-in-out forwards;
            animation-delay: .25s; }

        .pic-1 {
            animation: slide-in-img-1 200ms ease-in-out forwards;
            animation-delay: .55s; }

        .pic-2 {
            animation: slide-in-img-2 200ms ease-in-out forwards;
            animation-delay: .75s; }

        .pic-3 {
            animation: slide-in-img-3 200ms ease-in-out forwards;
            animation-delay: .95s; }

        .pic-4 {
            animation: slide-in-img-4 200ms ease-in-out forwards;
            animation-delay: 1.05s; }

        @keyframes slide-in-img {
            0% {
                transform: translateY(-50%);
                opacity: 0; }

            50% {
                transform: translateY(-25%);
                opacity: 0.5; }

            100% {
                transform: translateY(0);
                opacity: 1; } }

        @keyframes slide-in-img-1 {
            0% {
                transform: translateY(50%);
                opacity: 0; }

            50% {
                transform: translateY(25%);
                opacity: 0.5; }

            100% {
                transform: translateY(0);
                opacity: 1; } }

        @keyframes slide-in-img-2 {
            0% {
                transform: translateX(-50%);
                opacity: 0; }

            50% {
                transform: translateX(-25%);
                opacity: 0.5; }

            100% {
                transform: translateX(0);
                opacity: 1; } }

        @keyframes slide-in-img-3 {
            0% {
                transform: translateY(-50%);
                opacity: 0; }

            50% {
                transform: translateY(-25%);
                opacity: 0.5; }

            100% {
                transform: translateY(0);
                opacity: 1; } }

        @keyframes slide-in-img-4 {
            0% {
                transform: translateX(50%);
                opacity: 0; }

            50% {
                transform: translateX(25%);
                opacity: 0.5; }

            100% {
                transform: translateX(0);
                opacity: 1; } } } }
