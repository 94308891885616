.block-teaser-list {
    padding-top: 20*$base-width;
    padding-bottom: 20*$base-width;
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-top: 12*$base-width;
        padding-bottom: 12*$base-width; }

    &::after {
        content: "";
        max-width: 4rem;
        width: 100%;
        max-height: 30.1rem;
        height: 100%;
        background-color: $primary;
        position: absolute;
        bottom: 32rem;
        right: 0rem;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            top: 31.7rem; }
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            content: none; } }

    .wrapper {
        max-width: $boxed;
        margin: 0 auto; }

    .teaser-list {
        display: flex;
        flex-wrap: wrap;
        column-gap: 4*$base-width;
        row-gap: 4*$base-width;
        justify-content: center; }

    .headline-wrapper {
        padding-bottom: 20*$base-width;
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            padding-bottom: 5*$base-width; }
        &.align-center {
            text-align: center; } }

    .teaser-item {
        flex: 0 0 32%;
        width: 100%;
        box-shadow: 0 3px 40px rgba(0, 0, 0, 0.2);
        opacity: 0;

        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            flex: 0 0 45%; }

        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            flex: 0 0 100%; } }

    .content {
        width: 100%;
        padding: 8.75*$base-width;
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding: 5*$base-width; } }

    //ANIMATION
    .in-view {
        .teaser-item:nth-child(1) {
            animation: slide-in-teaser 200ms ease-in-out forwards;
            animation-delay: .35s; }

        .teaser-item:nth-child(2) {
            animation: slide-in-teaser 200ms ease-in-out forwards;
            animation-delay: .55s; }


        .teaser-item:nth-child(3) {
            animation: slide-in-teaser 200ms ease-in-out forwards;
            animation-delay: .75s; }


        .teaser-item:nth-child(4) {
            animation: slide-in-teaser 200ms ease-in-out forwards;
            animation-delay: .95s; }


        .teaser-item:nth-child(5) {
            animation: slide-in-teaser 200ms ease-in-out forwards;
            animation-delay: 1.15s; }


        .teaser-item:nth-child(6) {
            animation: slide-in-teaser 200ms ease-in-out forwards;
            animation-delay: 1.35s; }

        @keyframes slide-in-teaser {
            0% {
                transform: translateX(-50%);
                opacity: 0; }

            50% {
                transform: translateX(-25%);
                opacity: 0.5; }

            100% {
                transform: translateX(0);
                opacity: 1; } } } }


