.block-banner {
    padding-top: 16rem;
    padding-bottom: 16rem;
    padding-right: 2rem;
    padding-left: 2rem;
    @media (max-width: #{map-get($breakpoints, m)}) {
        padding-top: 10rem;
        padding-bottom: 10rem; }

    &.dark {
        background-image: url("../../assets/img/layout/background-dark.svg");
        h2, p, a {
            color: $light; }
        a {
            border: 2px solid $light;
            &:hover {
                border: 2px solid $primary; } } }

    &.light {
        background-color: $light; }


    .wrapper_banner {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        max-width: $boxed;
        margin: 0 auto; }

    .banner-inner-wrapper {
        max-width: $small;
        margin: 0 auto;
        text-align: center; } }

