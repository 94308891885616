
#footerheight {
    pointer-events: none;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        display: none; } }

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        position: relative; }
    .row {
        display: flex;
        max-width: $boxed;
        width: 100%;
        margin: 0 auto;
        padding: 10*$base-width 2rem;
        column-gap: 12.5 * $base-width;
        flex-wrap: wrap;
        row-gap: 4rem;
        @media only screen and (max-width: #{map-get($breakpoints, xxxxl)}) {
            column-gap: 10rem; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding: 5*$base-width 2rem; } }

    &.dark {
        background-image: url("../../img/layout/background-dark.svg");
        p {
            color: $light; }
        .office_hours {
            strong {
                color: $light; } }
        .social_media, .footer-menu {
            a {
                color: $light; } }
        .social_media {
            svg {
                path {
                    fill: $light; } } } }

    .semibold {
        font-weight: 500; }

    .footer-items {
        display: flex;
        flex-direction: column;
        row-gap: 1.3rem;

        svg {
            margin-right: 1.35rem;
            height: 110%;

            path {
                fill: $dark;
                transition: $transition; } }

        a {
            text-transform: none;
            &:hover {
                color: $primary;

                path {
                    fill: $primary;
                    transition: $transition; } } } }

    li {
        list-style-type: none;
        line-height: 3.5rem;
        a {
            text-transform: none; }
        &:hover {
            a {
                color: $primary; } } }


    .office_hours {
        strong {
            color: $dark;
            width: 86px;
            display: inline-block; }
        p {
            margin-bottom: 0; } }

    .adress-block {
        p {
            margin-bottom: 0; } }

    .social_media {
        a {
            align-items: center;
            display: flex;
            color: $dark; } }

    .footer-menu {
        a {
            color: $dark; } } }
