.block-text-media {
    position: relative;
    padding: 0 2.5*$base-width;

    &.dark {
        background-image: url("../../assets/img/layout/background-dark.svg"); }

    &.light {
        background-color: $light;
        .text {
            box-shadow: 0 3px 40px rgba(0, 0, 0, 0.2); } }

    .wrapper {
        display: flex;
        max-width: $boxed;
        margin: 0 auto;
        position: relative;
        @media only screen and (max-width: #{map-get($breakpoints, xxxxl)}) {
            max-width: $small; }
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            max-width: 1000px; }

        // .headline_vertical
        //     @media only screen and (max-width: #{map-get($breakpoints, xxl)})
        //         font-size: 4rem
        //     @media only screen and (max-width: #{map-get($breakpoints, m)})
        //         display: none

        .image {
            width: 58.9rem;
            height: 78.4rem;
            overflow: hidden;
            opacity: 0;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                width: 100%;
                height: 50rem; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                height: 40rem; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                height: 30rem; }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    width: 100%;
                    height: 100%; } } }

        .orange {
            position: relative;
            z-index: 2;
            &:after {
                content: "";
                position: absolute;
                max-width: 31rem;
                max-height: 86rem;
                height: 110%;
                width: 100%;
                background: $primary;
                z-index: -1;
                top: -4rem;
                // @media only screen and (max-width: #{map-get($breakpoints, xxl)})
                //     height: 92%
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    height: 100%;
                    max-height: 85rem; }
                @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                    content: none; } } }

        .text {
            width: 100%;
            padding: 10*$base-width 10*$base-width 7*$base-width;
            background-color: $light;
            position: absolute;
            z-index: 2;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding: 6*$base-width 10*$base-width 4*$base-width 7*$base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                padding: 7*$base-width 10*$base-width 4*$base-width 7*$base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding: 3.6rem 4.6rem 4.2rem 3.6rem; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding: 3.6rem 1.6rem 4.2rem 3.6rem; }

            strong {
                color: $dark;
                display: inline-block;
                padding-bottom: 10px; }
            .button-wrapper {
                a {
                    color: $dark;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    font-weight: 600;
                    transition: $transition;
                    &:hover {
                        color: $light;
                        transition: $transition;
                        .btn {
                            background-color: $primary;
                            border: 2px solid $primary;
                            transition: $transition; } } }
                .btn {
                    max-width: 30rem;
                    width: 100%;
                    height: 100%;
                    border: 2px solid $dark;
                    transition: $transition;
                    background-color: $light;
                    padding: 2*$base-width 4*$base-width;
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        padding: 1*$base-width 2*$base-width; } } } } }

    &.img-left {
        padding-top: 15rem;
        padding-bottom: 16rem;
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            padding-bottom: 10rem;
            padding-top: 0; }

        &.dark {
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                padding-top: 18rem;
                padding-bottom: 4rem; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding-top: 8rem; } }

        .wrapper {
            flex-direction: row;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex-direction: column; } }

        &::before {
            content: "";
            max-width: 4rem;
            width: 100%;
            max-height: 30.1rem;
            height: 100%;
            background-color: $primary;
            position: absolute;
            bottom: 16rem;
            right: 0rem;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                top: 11.7rem; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                content: none; } }

        .text {
            max-width: 48%;
            bottom: 8%;
            left: 510px;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                left: 429px;
                max-width: 48.9rem; }
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                bottom: unset;
                left: unset;
                position: relative;
                max-width: 100%; }
            // @media only screen and (max-width: #{map-get($breakpoints, m)})
            //     left: 40px
            //     bottom: 39px
            //     max-width: 45.9rem
            // @media only screen and (max-width: #{map-get($breakpoints, s)})
            //     max-width: 30.9rem
            // @media only screen and (max-width: #{map-get($breakpoints, xs)})
 }            //     left: 0

        .headline_vertical {
            position: absolute;
            bottom: 0;
            height: 50px;
            max-width: 50%;
            width: 100%;
            transform: none;
            &:after {
                content: attr(data-headline);
                position: absolute;
                left: -435px;
                bottom: 360px;
                width: 100%;
                transform: rotate(-90deg); }
            @media only screen and (max-width: #{map-get($breakpoints, xxxxl)}) {
                max-width: 65%;
                &:after {
                    left: -400px;
                    bottom: 310px; } }
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                &:after {
                    left: -355px;
                    bottom: 414px; } }
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                display: none; } }

        // .headline_vertical
        //     left: -38.5rem
        //     bottom: 28.3rem
        //     @media only screen and (max-width: #{map-get($breakpoints, xxl)})
        //         left: -311px
        //         bottom: 352px
        //     @media only screen and (max-width: #{map-get($breakpoints, xl)})
        //         bottom: 832px
        //     @media only screen and (max-width: #{map-get($breakpoints, l)})
        //         bottom: 652px
        //     @media only screen and (max-width: #{map-get($breakpoints, s)})
        //         bottom: 417px

        .image {
            padding-left: 4rem;
            @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                padding-left: 0; }

            &:after {
                top: -4rem;
                left: 0;
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    height: 130%; } } }


        .button-wrapper {
            a {
                margin-bottom: 2*$base-width; } }

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex-direction: row-reverse; } }

    &.img-right {
        padding-top: 8rem;
        padding-bottom: 16rem;
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding-bottom: 4rem; }

        .wrapper {
            flex-direction: row-reverse;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex-direction: column; } }
        &::before {
            content: "";
            max-width: 4rem;
            width: 100%;
            max-height: 30.1rem;
            height: 100%;
            background-color: $primary;
            position: absolute;
            top: 42rem;
            left: 0rem;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                top: 11.8rem; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                content: none; } }

        .text {
            max-width: 55.9rem;
            bottom: 8%;
            right: 509px;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                max-width: 47.9rem;
                bottom: 184px;
                right: 497px;
                padding: 8*$base-width 10*$base-width 7*$base-width 7*$base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                bottom: unset;
                position: relative;
                right: unset;
                max-width: 100%; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding: 3.4rem 5rem 4.6rem 3.6rem; } }


        .headline_vertical {
            position: absolute;
            bottom: 0;
            height: 50px;
            max-width: 50%;
            width: 100%;
            transform: none;
            &:after {
                content: attr(data-headline);
                position: absolute;
                right: -435px;
                bottom: 360px;
                width: 100%;
                transform: rotate(-90deg); }
            @media only screen and (max-width: #{map-get($breakpoints, xxxxl)}) {
                max-width: 65%;
                &:after {
                    right: -400px;
                    bottom: 310px; } }
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                &:after {
                    right: -295px;
                    bottom: 415px; } }
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                display: none; } }

        // .headline_vertical
        //     right: -214px
        //     bottom: 11.9rem
        //     @media only screen and (max-width: #{map-get($breakpoints, xxl)})
        //         right: -113px
        //         bottom: 219px

        .image {
            padding-right: 4rem;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                padding-right: 0;

                img {
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        width: 100%; } } } }

        .orange {
            &:after {
                top: -4rem;
                right: 0;
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    right: 66px; }
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    left: 0; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    height: 122%; } } }

        .headline-wrapper {
            max-width: 390px; } }

    // Animation
    &.in-view {
        &.img-left {
            .image {
                animation: slide-pic 200ms ease-in-out forwards;
                animation-delay: .25s; } }

        &.img-right {
            .image {
                animation: slide-pic1 200ms ease-in-out forwards;
                animation-delay: .55s; } }

        @keyframes slide-pic {
            0% {
                transform: translateX(-50%);
                opacity: 0; }

            50% {
                transform: translateX(-25%);
                opacity: 0.5; }

            100% {
                transform: translateX(0);
                opacity: 1; } }

        @keyframes slide-pic1 {
            0% {
                transform: translateX(50%);
                opacity: 0; }

            50% {
                transform: translateX(25%);
                opacity: 0.5; }

            100% {
                transform: translateX(0);
                opacity: 1; } } } }
