.block-gallery2 {
    padding: 0 2.5*$base-width;
    margin-bottom: 12rem;
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        margin-bottom: 10*$base-width; }

    .gallery-sort-images-row {
        padding: 0;
        margin: 0; }
    .gallery-sort-image-container {
        margin: 0;
        max-height: 500px;
        width: 100%;
        overflow: hidden;
        img,picture {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative; }
        a {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            &:hover {
                .gallery-sort-overlay {
                    opacity: 0.6;
                    svg {
                        opacity: 1; } } } }
        .gallery-sort-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $primary;
            opacity: 0;
            transition: $transition;
            z-index: 9;
            align-items: center;
            justify-content: center;
            display: flex;

            svg {
                max-width: 8rem;
                width: 100%;
                max-height: 8rem;
                height: 100%;
                fill: $light;
                opacity: 1; } } } }



.fancybox-caption {
    font-size: 25px;
    @media (max-width: #{map-get($breakpoints, l)}) {
        font-size: 20px; }
    @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 16px; } }
