* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html {
    font-size: 10px; }

body {
    -webkit-text-size-adjust: none;
    font-size: 1.8rem;
    color: $dark;
    line-height: 160%;
    font-weight: 400;
    font-family: $font-default;
    @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 1.6rem; } }


main {
    background-color: #fff;
    position: relative;
    z-index: 5;
    .block {
        z-index: 10; } }
