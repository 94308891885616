.block-slider-simple {
  padding-top: 8rem;
  padding-bottom: 30*$base-width;
  padding-left: 12rem;
  overflow: hidden;
  @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
    padding-left: 12rem; }
  @media only screen and (max-width: #{map-get($breakpoints, l)}) {
    padding-bottom: 10rem;
    padding-top: 0;
    padding-left: 2rem;
    padding-right: 2rem; }
  @media only screen and (max-width: #{map-get($breakpoints, s)}) {
    padding-bottom: 10rem;
    padding-top: 0; }

  .wrapper {
    max-width: 1920px;
    @media only screen and (max-width: 1920px) {
      max-width: 1720px; }
    width: 100%;
    margin-left: auto;
    display: flex;
    position: relative;
    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
      flex-direction: column; }

    .headline_vertical {
      left: -34.5rem;
      bottom: 21rem;
      @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        bottom: unset;
        left: unset;
        position: relative;
        transform: unset;
        opacity: 1;
        padding-bottom: 10*$base-width;
        text-align: center;
        line-height: 1;
        font-family: $font-headline;
        font-size: 4rem;
        color: $dark;
        font-weight: 400; }

      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        padding-bottom: 5*$base-width; } }

    .image {
      max-width: 58.9rem;
      max-height: 78.4rem;
      object-fit: cover;
      z-index: 2;
      @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        max-width: 100%;
        max-height: 50rem;
        width: 100%;
        height: 100%;
        overflow: hidden; }

      @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        max-height: 40rem; }

      // @media only screen and (max-width: #{map-get($breakpoints, m)})
      //   max-width: 38.9rem

      img {
        width: 100%;
        height: 100%;
        object-fit: cover; }

      &:after {
        content: "";
        position: absolute;
        top: -4rem;
        left: -4rem;
        max-width: 31rem;
        max-height: 86rem;
        height: 110%;
        width: 100%;
        background: $primary;
        z-index: -1;
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
          height: 112%; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
          height: 50%;
          top: 10rem; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          content: unset; } } }

    .slider-simple {
      width: 100%;
      z-index: 4;
      position: absolute;
      bottom: 10rem;
      right: 0;
      bottom: 7rem;
      left: 51rem;
      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        left: 31rem; }
      @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        position: relative;
        left: unset;
        top: unset;
        bottom: unset;
        right: unset; }

      .content {
        max-width: 51.2rem;
        width: 100%;
        // max-height: 40.7rem
        height: 100%;
        padding: 6rem 7rem 7rem;
        background-color: $light;
        box-shadow:  0px 15px 28px rgba(0, 0, 0, 0.2);
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
          // box-shadow: none
          padding: 4rem;
          max-width: 100%; }
        a {
          font-weight: 600;
          text-transform: uppercase; } } }

    .owl-carousel {
      .owl-stage-outer {
        overflow: visible;
        // @media only screen and (max-width: #{map-get($breakpoints, l)})
 }        //   overflow: hidden

      .owl-item {
        opacity: 0;
        transition: opacity 500ms;
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
          opacity: 1; } }

      .owl-item.active {
        opacity: 1; }

      .owl-nav {
        display: flex;
        justify-content: center;
        column-gap: 1rem;
        position: absolute;
        top: -91px;
        right: 664px;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          right: 380px; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
          left: unset;
          right: unset;
          justify-content: center;
          width: 100%;
          top: unset;
          padding-top: 6.25*$base-width;
          position: relative; }

        button {
          max-width: 7rem;
          width: 100%;
          max-height: 7rem;
          height: 100%;

          &:hover {
            path {
                fill: $primary; }
            #runter {
                fill: $dark; } } }

        .owl-carousel-arrow {
          display: inline-block;
          max-width: 7rem;
          width: 100%;
          max-height: 7rem;
          height: 100%; } }

      .owl-dots {
        display: flex;
        justify-content: center;
        column-gap: 1rem;
        position: absolute;
        bottom: -79px;
        right: 663px;
        justify-content: end;
        width: 30%;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          right: 380px; }
        @media only screen and (max-width: #{map-get($breakpoints, l)}) {
          display: none; }
        // @media only screen and (max-width: #{map-get($breakpoints, s)})
        //   bottom: -39px

        button {
          width: 100%;
          max-width: 1.5rem;


          span {
            max-width: 1.5rem;
            width: 100%;
            max-height: 1.5rem;
            height: 100%;
            display: inline-block;
            background-color: $dark;
            clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%); }

          .owl-dot {
            &.active {
              span {
                background-color: $primary; } } } } } } } }
