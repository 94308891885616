/*Variables: Form * */

$success: green;
$error: red;

.block-form {
  padding-bottom: 10*$base-width;
  .wrapper {
    max-width: $boxed;
    margin: 0 auto;
    padding: 0 2.5*$base-width;

    form {
      .form-inner {
        .form-field-group {
          margin: 4 * $base-width 0;

          label {
            display: inline-block;
            margin-bottom: $base-width; }

          input[type="text"] {
            width: 100%;
            height: 6 * $base-width;
            padding: 2 * $base-width; }

          textarea {
            width: 100%;
            min-height: 15 * $base-width;
            padding: 2 * $base-width; }

          .checkbox {
            display: flex;

            #dsgvo {
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: $base-width;

              &.error {
                &::after {
                  content: '';
                  width: 25px;
                  height: 25px;
                  position: absolute;
                  background-color: rgba($error,.25); }

                &:checked {
                  &::after {
                    background-color: rgba($error,.0); } } } } } }

        #confirm {
          position: absolute;
          left: -20000px; } }

      .form-success,
      .form-error {
        width: 100%;
        border: 2px solid $error;
        background-color: rgba($error,.2);
        padding: 3rem;
        margin-bottom: 3rem; }

      .form-success {
          border: 2px solid $success;
          background-color: rgba($success,.25); }

      .error {
        border-color: $error;
        background-color: rgba($error,.25); }

      .btn {
        max-width: fit-content;
        width: 100%;
        height: 100%;
        border: 2px solid $dark;
        transition: $transition;
        background-color: $light;
        padding: 2*$base-width 4*$base-width;
        color: $dark;
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: 600;
        transition: $transition;
        &:hover {
            color: $light;
            transition: $transition;
            background-color: $primary;
            border: 2px solid $primary;
            transition: $transition; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding: 1*$base-width 2*$base-width; } } }

    .contact-item {
      padding: 1*$base-width 0;
      a {
        display: flex;
        align-items: center;
        svg {
          max-width: 25px;
          max-height: 25px;
          margin-right: 10px;
          fill: $primary; } }

      &:hover {
        a {
          color: $primary; } } } } }
