.block.block-hero {
    background-image: url("../../assets/img/layout/background-dark.svg");
    background-repeat: repeat;
    max-height: 1177px;
    height: 129vh;
    padding-top: 24rem;
    padding-left: 2.5*$base-width;
    padding-right: 2.5*$base-width;
    margin-bottom: 14.625*$base-width;
    position: relative;
    @media (max-width: #{map-get($breakpoints, xxxxl)}) {
        max-height: 1183px; }
    @media (max-width: #{map-get($breakpoints, xxl)}) {
        max-height: 1050px; }
    @media (max-width: #{map-get($breakpoints, xl)}) {
        max-height: 964px; }
    @media (max-width: #{map-get($breakpoints, l)}) {
        padding-top: 12rem;
        max-height: 880px; }
    @media (max-width: #{map-get($breakpoints, m)}) {
        max-height: 780px; }
    @media (max-width: #{map-get($breakpoints, s)}) {
        max-height: 650px; }
    @media (max-width: #{map-get($breakpoints, xs)}) {
        max-height: 550px; }

    .wrapper {
        max-width: $hero;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;

        .hero_images {
            display: flex;
            position: relative;
            @media (max-width: #{map-get($breakpoints, l)}) {
                flex-direction: column; }

            .picture-0 {
                padding-top: 4rem;
                max-width: 96rem;
                max-height: 60rem;
                overflow: hidden;
                width: 100%;
                height: 100%;
                opacity: 0;
                @media (max-width: #{map-get($breakpoints, l)}) {
                    padding-top: 0;
                    margin-top: 10rem; }


                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    @media (max-width: #{map-get($breakpoints, xxl)}) {
                        height: 80%; }
                    @media (max-width: #{map-get($breakpoints, l)}) {
                        height: 100%; } } }

            .picture-1 {
                width: 100%;
                max-width: 76rem;
                height: 76rem;
                overflow: hidden;
                opacity: 0;
                @media (max-width: #{map-get($breakpoints, xxl)}) {
                    max-width: 62rem;
                    height: 65rem; }
                @media (max-width: #{map-get($breakpoints, xl)}) {
                    max-width: 56rem;
                    height: 55rem; }
                @media (max-width: #{map-get($breakpoints, l)}) {
                    display: none; }

                picture {
                    width: 100%;
                    height: 100%;
                    object-fit: cover; }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    @media (max-width: #{map-get($breakpoints, l)}) {
                        height: 50%; } } }

            .orange {
                position: relative;
                z-index: 2;
                padding-left: 4rem;
                &:after {
                    content: "";
                    position: absolute;
                    top: -4rem;
                    left: 0;
                    max-width: 40rem;
                    max-height: 84rem;
                    height: 110%;
                    width: 100%;
                    background: $primary;
                    z-index: -1;
                    @media (max-width: #{map-get($breakpoints, xxl)}) {
                        height: 113%; }
                    @media (max-width: #{map-get($breakpoints, xl)}) {
                        height: 115%; }
                    @media (max-width: #{map-get($breakpoints, l)}) {
                        height: 61%; } } } }

        .headline-wrapper {
            position: absolute;
            bottom: 4.6rem;
            left: 10*$base-width;
            word-break: break-word;
            opacity: 0;
            @media (max-width: #{map-get($breakpoints, xxxxl)}) {
                // bottom: -4.6rem
                left: 5*$base-width;
                max-width: 100%; }
            @media (max-width: #{map-get($breakpoints, xxl)}) {
                bottom: 0;
                left: 0; }
            @media (max-width: #{map-get($breakpoints, xl)}) {
                // bottom: -4rem
                h1 {
                    line-height: 130%; } }
            @media (max-width: #{map-get($breakpoints, l)}) {
                top: 20px; }
            @media (max-width: #{map-get($breakpoints, m)}) {
                left: 0;
                padding-right: 2*$base-width; }

            h1 {
                @media (max-width: #{map-get($breakpoints, xl)}) {
                    font-size: 4rem; }
                @media (max-width: #{map-get($breakpoints, m)}) {
                    font-size: 3.5rem; }
                @media (max-width: #{map-get($breakpoints, s)}) {
                    font-size: 3rem; } } }




        .social_media {
            position: absolute;
            bottom: -47px;
            padding-left: 10*$base-width;
            @media (max-width: #{map-get($breakpoints, xxxxl)}) {
                padding-left: 5*$base-width; }
            @media (max-width: #{map-get($breakpoints, xl)}) {
                bottom: -5*$base-width;
                padding-left: 0; }

            h4 {
                padding-bottom: 2rem;
                color: $light;
                text-transform: uppercase;
                font-weight: 300;
                @media (max-width: #{map-get($breakpoints, l)}) {
                    display: none; } }
            .hero-items {
                display: flex;
                column-gap: 2*$base-width;
                @media (max-width: #{map-get($breakpoints, xl)}) {
                    column-gap: 0; } }
            a {
                transition: $transition;
                &:hover {
                    #instagram_icon, #facebook_icon {
                        fill: $primary;
                        transition: $transition; } }

                #instagram_icon, #facebook_icon {
                    transition: $transition; }

                svg {
                    @media (max-width: #{map-get($breakpoints, xl)}) {
                        height: 7rem;
                        width: 8rem; }
                    @media (max-width: #{map-get($breakpoints, m)}) {
                        height: 6rem;
                        width: 7rem; } } } }

        // Animation
        &.in-view {
            .headline-wrapper {
                animation: sparkle-in 1s ease-in-out forwards;
                animation-delay: 1s; }

            .picture-0 {
                animation: fadeinpicture 200ms ease-in-out forwards;
                animation-delay: .25s; }

            .picture-1 {
                animation: fadeinpicture1 200ms ease-in-out forwards;
                animation-delay: .55s; }

            @keyframes sparkle-in {
                0% {
                    opacity: 0; }
                50% {
                    opacity: 0.5; }
                100% {
                    opacity: 1; } }

            @keyframes fadeinpicture {
                0% {
                    transform: translateX(-50%);
                    opacity: 0; }

                50% {
                    transform: translateX(-25%);
                    opacity: 0.5; }

                100% {
                    transform: translateX(0);
                    opacity: 1; } }

            @keyframes fadeinpicture1 {
                0% {
                    transform: translateX(50%);
                    opacity: 0; }

                50% {
                    transform: translateX(25%);
                    opacity: 0.5; }

                100% {
                    transform: translateX(0);
                    opacity: 1; } } } } }



// #block_0c42cc772ea12549a7d8e83209615489

//     @media (max-width: #{map-get($breakpoints, xxl)})
//         max-height: 941px

//     @media (max-width: #{map-get($breakpoints, xl)})
//         margin-bottom: 0
//     @media (max-width: #{map-get($breakpoints, m)})
//         margin-bottom: 140px
//     @media (max-width: #{map-get($breakpoints, s)})
//         height: 55rem

//     @media (max-width: #{map-get($breakpoints, xs)})
//         height: 50rem
//         margin-bottom: 6rem

//     .headline-wrapper
//         @media (max-width: #{map-get($breakpoints, l)})
//             top: 50px
//         @media (max-width: #{map-get($breakpoints, s)})
//             top: 0px
