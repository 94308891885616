#errorPage {
    max-width: 1640px;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: auto;
    padding-top: 31.25*$base-width;

    h1 {
        color: $dark; }

    .btn {
        max-width: 21.3rem;
        cursor: pointer;
        &:hover {
            a {
                color: $light; } } } }
