a {
    color: $dark;
    text-decoration: none;
    display: inline-block;
    transition: $transition; }

strong,
.bold {
    font-weight: 600;
    color: $primary; }


h1 {
    font-family: $font-headline;
    font-size: 5rem;
    color: $light;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 150%;
    @media (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 4rem; }
    @media (max-width: #{map-get($breakpoints, l)}) {
        font-size: 3.5rem; } }

h2 {
    font-family: $font-headline;
    font-size: 5rem;
    color: $dark;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 130%;
    margin-bottom: 4*$base-width;
    @media (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 4rem; }
    @media (max-width: #{map-get($breakpoints, l)}) {
        font-size: 3.5rem; } }


h3 {
    font-family: $font-headline;
    font-size: 4rem;
    color: $primary;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 130%;
    margin-bottom: 4*$base-width;
    word-break: break-word;
    @media (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 3.5rem;
        line-height: 4.2rem;
        margin-bottom: 2*$base-width; }
    @media (max-width: #{map-get($breakpoints, l)}) {
        font-size: 3rem;
        line-height: 4rem; } }

h4 {
    font-family: $font-headline;
    font-size: 2.5rem;
    color: $primary;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    @media (max-width: #{map-get($breakpoints, l)}) {
        font-size: 2.2rem; }
    @media (max-width: #{map-get($breakpoints, s)}) {
        font-size: 2rem;
        word-break: break-word; } }

p {
    font-family: $font-default;
    font-size: 1.8rem;
    color: $dark;
    line-height: 3rem;
    font-weight: 400;
    margin-bottom: 4*$base-width;
    @media (max-width: #{map-get($breakpoints, xxl)}) {
        margin-bottom: 3*$base-width; }
    @media (max-width: #{map-get($breakpoints, m)}) {
        font-size: 1.6rem;
        line-height: 1.8;
        margin-bottom: 2.5*$base-width; } }

.headline_vertical {
    color: $secondary;
    opacity: 0.5;
    text-transform: uppercase;
    font-size: 5rem;
    font-weight: 600;
    transform: rotate(-90deg);
    position: absolute;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        font-size: 4rem; } }



.btn {
    border: 2px solid $dark;
    transition: $transition;
    padding: 2*$base-width 2*$base-width;
    a {
        text-transform: uppercase;
        @media (max-width: #{map-get($breakpoints, m)}) {
            font-size: 1.6rem; } }

    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding: 2*$base-width 2*$base-width; }

    &:hover {
        color: $light;
        transition: $transition;
        background-color: $primary;
        border: 2px solid $primary; } }

.header_solgan {
    p {
        margin-bottom: 0; } }
