/*EXAMPLE

 font-face
     font-family: 'Foo'
     font-style: normal
     font-weight: 300
    src: url('../../fonts/foo-300.woff2') format('woff2'),
    url('../../fonts/foo-300.woff') format('woff')
     font-display: swap */

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/open-sans-v34-latin-300.woff2') format('woff2'),
  url('../../fonts/open-sans-v34-latin-300.woff') format('woff') {} }


/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/open-sans-v34-latin-regular.woff2') format('woff2'),
  url('../../fonts/open-sans-v34-latin-regular.woff') format('woff') {} }


/* open-sans-500 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/open-sans-v34-latin-500.woff2') format('woff2'),
  url('../../fonts/open-sans-v34-latin-500.woff') format('woff') {} }


/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/open-sans-v34-latin-600.woff2') format('woff2'),
  url('../../fonts/open-sans-v34-latin-600.woff') format('woff') {} }


/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/open-sans-v34-latin-700.woff2') format('woff2'),
  url('../../fonts/open-sans-v34-latin-700.woff') format('woff') {} }


/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../../fonts/open-sans-v34-latin-800.woff2') format('woff2'),
  url('../../fonts/open-sans-v34-latin-800.woff') format('woff') {} }



//*****************************************************
/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/raleway-v28-latin-regular.woff2') format('woff2'),
  url('../../fonts/raleway-v28-latin-regular.woff') format('woff') {} }


/* raleway-500 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: norma;
  font-weight: 500;
  src: url('../../fonts/raleway-v28-latin-500.woff2') format('woff2'),
  url('../../fonts/raleway-v28-latin-500.woff') format('woff') {} }


/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/raleway-v28-latin-600.woff2') format('woff2'),
  url('../../fonts/raleway-v28-latin-600.woff') format('woff') {} }


/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/raleway-v28-latin-700.woff2') format('woff2'),
  url('../../fonts/raleway-v28-latin-700.woff') format('woff') {} }


/* raleway-800 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('../../fonts/raleway-v28-latin-800.woff2') format('woff2'),
  url('../../fonts/raleway-v28-latin-800.woff') format('woff') {}



  /* nothing-you-could-do-regular - latin */ }
@font-face {
  font-family: 'Nothing You Could Do';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/nothing-you-could-do-v15-latin-regular.woff2') format('woff2'),
  url('../../fonts/nothing-you-could-do-v15-latin-regular.woff') format('woff') {} }

