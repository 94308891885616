.block-text-columns {
    max-width: $boxed;
    margin: 0 auto;
    .text-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4 * $base-width;

        .col {
            padding: 4 * $base-width;

            &.col-1 {
                flex: 0 0 100%; }

            &.col-2 {
                flex: 0 0 50%;

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 100%; } }

            &.col-3 {
                flex: 0 0 33.333%;

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    flex: 0 0 50%; }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 100%; } }

            &.col-4 {
                flex: 0 0 25%;

                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    flex: 0 0 33.333%; }

                @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                    flex: 0 0 50%; }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 100%; } }

            ul {
                padding-left: 3 * $base-width;

                li {
                    margin-bottom: $base-width; } }


            .btn {
                margin-top: 2 * $base-width; } } } }
