html.open-nav {
  // @media only screen and (max-width: #{map-get($breakpoints, l)})
  //@media only screen and (max-width: #{map-get($breakpoints, xl)})
  //    overflow: hidden

  //body:before
  //    display: block

  //// @media only screen and (max-width: #{map-get($breakpoints, l)})
  //@media only screen and (max-width: #{map-get($breakpoints, l)})
 }  //    display: none

body {
    &::before {
        content: "";
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(0,0,0,1), rgba(0,0,0,0));
        z-index: 9;
        transition: $transition; } }


header {
    background-image: url("../../img/layout/background-dark.svg");
    background-repeat: repeat;
    width: 100%;
    max-height: 140px;
    height: 100%;
    position: fixed;
    z-index: 1000;
    padding-right: 2.5*$base-width;
    padding-left: 2.5*$base-width;

    .header_inner {
        display: flex;
        max-width: $boxed;
        padding-top: 1.25*$base-width;
        margin: auto;
        justify-content: space-between;
        align-items: center; }

    .header_left, .header-right {
        display: flex;
        align-items: center; }

    .logo {
        height: 9.5rem;
        svg {
            transition: $transition;
            max-width: 115px;
            width: 100%;
            max-height: 115px;
            height: 100%;
            @media (max-width: #{map-get($breakpoints, m)}) {
                width: 90%; } } }


    &.sticky {
        max-height: 114px;
        transition: $transition;
        .logo {
            height: 9.5rem;
            svg {
                transition: $transition;
                max-width: 95px;
                width: 100%;
                max-height: 94px;
                height: 100%;
                @media (max-width: #{map-get($breakpoints, m)}) {
                    width: 90%; } } } }

    .header_solgan {
        margin-left: 7.5*$base-width;
        max-width: 22.4rem;
        .color-orange {
            color: $primary; }

        p {
            font-family: $font-handwriting;
            color: $light;
            font-size: 2.7rem; }

        @media (max-width: #{map-get($breakpoints, xxl)}) {
            margin-left: 3*$base-width; }

        @media (max-width: #{map-get($breakpoints, xl)}) {
            display: none; } }

    .meta-navi {
        display: flex;
        justify-content: end;
        padding-bottom: 3.375*$base-width;
        @media (max-width: #{map-get($breakpoints, l)}) {
            display: none; }
        li {
            list-style: none; }
        a {
            color: $light;
            font-weight: 500;
            font-size: 1.4rem;

            path {
                transition: $transition; }

            &:hover {
                color: $primary;
                transition: $transition;
                path {
                    stroke: $primary;
                    fill: $primary;
                    transition: $transition; } } } }

    .meta-menu {
        display: flex;
        column-gap: 6*$base-width;
        margin-right: 6*$base-width;
        @media (max-width: #{map-get($breakpoints, xs)}) {
            margin-right: 3*$base-width;
            column-gap: 3*$base-width; }
        @media (max-width: 335px) {
            margin-right: 0; } }


    .metanavi_phone, .metanavi_mail {
        a {
            display: flex;
            align-items: center; }

        svg {
            margin-right: 1.25*$base-width; } }



    .metanavi_phone {
        margin-right: 6*$base-width;
        @media (max-width: #{map-get($breakpoints, s)}) {
            margin-right: 0; } }

    .main-menu {
        display: flex;
        column-gap: 5*$base-width;
        @media (max-width: #{map-get($breakpoints, xxl)}) {
            column-gap: 3.5*$base-width; }
        @media (max-width: #{map-get($breakpoints, l)}) {
            display: none; }

        li {
            list-style-type: none;
            position: relative;
            cursor: pointer;
            &.active {
                a {
                    color: $primary; } }

            &::before {
                content: "";
                max-width: .9rem;
                width: 100%;
                max-height: .9rem;
                height: 100%;
                background-image: url("../../img/layout/achteck.svg");
                color: $primary;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%); }
            @media (max-width: #{map-get($breakpoints, l)}) {
                &::before {
                    content: none; } }
            &:first-child {
                &::before {
                    content: none; }
                a {
                    margin-left: 0; } }

            a {
                color: $light;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 1.7rem;
                margin-left: 5*$base-width;
                &:hover {
                    color: $primary; }

                @media (max-width: #{map-get($breakpoints, xxl)}) {
                    margin-left: 3.5*$base-width; }
                @media (max-width: #{map-get($breakpoints, l)}) {
                    margin-left: 0; } } } }

    .show-nav {
        @media (max-width: #{map-get($breakpoints, l)}) {
            .inner-nav {
                position: fixed;
                left: 0;
                top: 110px;
                transition: all 0.3s ease-in-out;
                background-image: url("../../img/layout/background-dark.svg");
                background-repeat: repeat;
                padding: 3.4rem;
                width: 100%;
                height: 100%;
                z-index: 99;
                .meta-navi-mobile {
                    display: flex;
                    justify-content: center;
                    margin-top: 4*$base-width;
                    row-gap: 1*$base-width;
                    li {
                        list-style-type: none;
                        &:before {
                            display: none; }
                        a {
                            color: $light;
                            &:hover {
                                color: $primary; } } } }

                .metanavi_phone, .metanavi_mail {
                    a {
                        color: $light;
                        &:hover {
                            color: $primary;

                            path {
                                fill: $primary;
                                stroke: $primary;
                                transition: $transition; } } }
                    path {
                        transition: $transition; } } } }

        .meta-navi-mobile {
            @media (max-width: 670px) {
                flex-wrap: wrap; } } }




    .main-menu {
        &.open {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 1.8rem;

            li {
                text-align: center;
                &.show {
                    .level-1 {
                        position: relative;
                        top: 0;
                        li {
                            padding-left: 5*$base-width;
                            &:first-child {
                                padding-left: 0; }
                            &:after {
                                margin-left: 8.375*$base-width; } } } } } }
        .children {
            .level-1 {
                display: none; }


            &.show {
                a {
                    color: $primary; }

                body {
                    &::before {
                        display: flex; } }

                .level-1 {
                    display: flex;
                    flex-direction: column;
                    background-image: url("../../img/layout/background-dark.svg");
                    background-repeat: repeat;
                    position: absolute;
                    top: 3.9rem;

                    li {
                        list-style-type: none;
                        padding: 2*$base-width 5*$base-width 2*$base-width 0;
                        position: relative;
                        &::before {
                            display: none; }

                        &::after {
                            content: "";
                            width: 19.75rem;
                            height: .1rem;
                            background-color: $light;
                            position: absolute;
                            left: 2.5*$base-width;
                            top: 0;
                            opacity: 0.25; }

                        &:first-child {
                            a {
                                margin-left: 4rem; }
                            &::after {
                                content: none; } }
                        &:last-child {
                            padding-bottom: 3rem; }

                        a {
                            color: $light;
                            transition: $transition;

                            &:hover {
                                color: $primary;
                                transition: $transition; } }

                        &.active {
                            a {
                                color: $primary; } } } } }

            .children ul li:hover a {
                color: $primary; } } }

    .meta-navi-mobile {
        display: none; }

    .main-menu &.open {
        .meta-navi-mobile {
            display: flex;
            position: fixed;
            z-index: 100;
            top: 40rem;
            left: 22rem;
            max-width: 80rem;
            width: 100%;
            li {
                list-style: none;
                a {
                    color: $light;
                    &:hover {
                        color: $primary; } } }

            .metanavi_phone, .metanavi_mail {
                a {
                    color: $light;

                    &:hover {
                        color: $primary;
                        transition: $transition;
                        path {
                            stroke: $primary;
                            fill: $primary;
                            transition: $transition; } } } } } }

    button {
        border: transparent;
        background-color: transparent; }

    .navbar-toggler {
        display: none;
        @media (max-width: #{map-get($breakpoints, l)}) {
            display: flex;
            align-items: center;
            z-index: 99;
            cursor: pointer; } }

    .navbar-toggler.open .line-toggle.top {
        top: 50%;
        transform: rotate(45deg) translateY(-50%);
        transition: all 0.3s ease-in-out; }

    .navbar-toggler.open .line-toggle.middle {
        opacity: 0; }

    .navbar-toggler.open .line-toggle.bottom {
        bottom: 45%;
        transform: rotate(-45deg) translateY(-50%);
        transition: all 0.3s ease-in-out; }

    .toggle {
        height: 49px;
        width: 49px;
        position: relative; }

    .line-toggle.top {
        top: 15px;
        left: 0;
        right: 0;
        transition: $transition; }

    .line-toggle.middle {
        top: 49%;
        transition: $transition;
        left: 0;
        right: 0; }

    .line-toggle.bottom {
        bottom: 15px;
        left: 0;
        right: 0;
        transition: $transition; }

    .line-toggle {
        height: 2px;
        background-color: $primary;
        width: 30px;
        display: inline-block;
        position: absolute;
        margin: 0 auto; } }
