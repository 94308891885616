/* Third-Party */
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min';
@import '~owl.carousel/dist/assets/owl.carousel';

/* Globals */
@import './globals/vars';
@import './globals/mixins';
@import './globals/fonts';
@import './globals/base';
@import './globals/bem-grid-beta';

/* Layout-Parts */
@import './layout/typo';
@import './layout/header';
@import './layout/footer';
@import './layout/404';

/* Blocks */
@import '../../blocks/hero/style';
@import '../../blocks/text-media/style';
@import '../../blocks/gallery/style';
@import '../../blocks/employee/style';
@import '../../blocks/banner/style';
@import '../../blocks/teaser-list/style';
@import '../../blocks/text-media-list/style';
@import '../../blocks/slider-simple/style';
@import '../../blocks/gallery2/style';
@import '../../blocks/text-columns/style';
@import '../../blocks/form/style';
