.block-text-media-list {
    padding-top: 20*$base-width;
    padding-bottom: 20*$base-width;
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;
    @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
        padding-top: 0; }

    &::before {
        content: "";
        max-width: 4rem;
        width: 100%;
        max-height: 30.1rem;
        height: 100%;
        background-color: $primary;
        position: absolute;
        bottom: -150px;
        left: 0rem;
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            top: 11.8rem; }
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            content: none; } }

    &.dark {
        background-image: url("../../assets/img/layout/background-dark.svg"); }

    &.light {
        background-color: $light; }

    .wrapper {
        display: flex;
        max-width: $boxed;
        margin: 0 auto;
        position: relative;
        align-items: center;
        column-gap: 8rem;
        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            max-width: 1200px; }
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex-direction: column;
            row-gap: 0; }

        .image {
            max-width: 78.4rem;
            max-height: 58.9rem;
            object-fit: cover;
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            opacity: 0;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                width: 100%;
                max-width: 100%;
                height: 50rem; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                height: 40rem; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                height: 30rem; }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover; }

            &:after {
                content: "";
                position: absolute;
                max-width: 43.2rem;
                max-height: 66.9rem;
                height: 117%;
                width: 100%;
                background: $primary;
                z-index: -1;
                @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
                    height: 123%; }
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    max-width: 33.2rem;
                    height: 120%; }
                @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                    display: none; } } }

        .text {
            max-width: 55.9rem;
            width: 100%;
            padding: 10*$base-width 10*$base-width 7*$base-width;
            background-color: $light;
            z-index: 2;
            box-shadow: 0 3px 40px rgba(0, 0, 0, 0.2);
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                padding: 6*$base-width 10*$base-width 4*$base-width 7*$base-width;
                max-width: 100%; }
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                max-width: 100%; }
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                padding: 7*$base-width 10*$base-width 4*$base-width 7*$base-width; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding: 3.6rem 4.6rem 4.2rem 3.6rem; }
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                padding: 3.6rem 3.6rem 4.2rem 3.6rem; }
            p {
                margin-bottom: 2*$base-width;
                strong {
                    color: $dark; } }
            ul {
                max-width: 380px;
                width: 100%;
                margin-left: 16px; }

            li {
                list-style-type: none;
                position: relative;
                padding-bottom: 1rem;
                &:last-child {
                    padding-bottom: 0; }

                &::before {
                    content: "";
                    max-width: .9rem;
                    width: 100%;
                    max-height: .9rem;
                    height: 100%;
                    background-image: url("../../assets/img/layout/achteck.svg");
                    color: $primary;
                    position: absolute;
                    left: -15px;
                    top: 10px; }

                @media (max-width: #{map-get($breakpoints, l)}) {
                    font-size: 1.6rem; } }

            .button-wrapper {
                a {
                    color: $dark;
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    font-weight: 600;
                    transition: $transition;
                    &:hover {
                        color: $light;
                        transition: $transition;
                        .btn {
                            background-color: $primary;
                            border: 2px solid $primary;
                            transition: $transition; } } }
                .btn {
                    max-width: 30rem;
                    width: 100%;
                    height: 100%;
                    border: 2px solid $dark;
                    transition: $transition;
                    background-color: $light;
                    padding: 2*$base-width 4*$base-width;
                    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                        padding: 1*$base-width 2*$base-width; } } } } }

    &.img-left {
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            padding-bottom: 20*$base-width; }
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            padding-bottom: 35*$base-width; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            padding-bottom: 60*$base-width; }
        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
            padding-bottom: 50*$base-width; }
        .wrapper {
            flex-direction: row; }

        .image {
            padding-left: 4rem;
            @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                padding-left: 0; }

            &:after {
                top: -4rem;
                left: 0;
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    height: 130%; } } }


        .button-wrapper {
            a {
                margin-bottom: 2*$base-width; } }

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex-direction: row-reverse; } }

    &.img-right {
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            padding-bottom: 10rem; }

        .wrapper {
            flex-direction: row-reverse;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                flex-direction: column; } }


        .image {
            padding-right: 4rem;
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding-right: 0; }
            @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
                padding-left: 0;
                padding-right: 0;

                img {
                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        width: 100%; } } }


            &:after {
                top: -5rem;
                right: 0;
                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    left: 0; }
                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    height: 100%; } } }


        .headline-wrapper {
            max-width: 390px; } }

    // Animation
    &.in-view {
        &.img-left {
            .image {
                animation: slide-pic 200ms ease-in-out forwards;
                animation-delay: .25s; } }

        &.img-right {
            .image {
                animation: slide-pic1 200ms ease-in-out forwards;
                animation-delay: .55s; } }

        @keyframes slide-pic {
            0% {
                transform: translateX(-50%);
                opacity: 0; }

            50% {
                transform: translateX(-25%);
                opacity: 0.5; }

            100% {
                transform: translateX(0);
                opacity: 1; } }

        @keyframes slide-pic1 {
            0% {
                transform: translateX(50%);
                opacity: 0; }

            50% {
                transform: translateX(25%);
                opacity: 0.5; }

            100% {
                transform: translateX(0);
                opacity: 1; } } } }
