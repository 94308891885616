// Base
$base-width: .8rem; //Abstände

// Fonts
$font-headline: 'Open Sans';
$font-default: 'Raleway';
$font-handwriting: 'Nothing You Could Do';


// General
$dark: #1D140F;
$light: #fff;

// Brand Colors
$primary: #E87613;
$secondary: #9FA1A2;

// Others
$divider: rgba($dark, .3);

// Widths
$max: 2560px;
$boxed: 1600px;
$small: 1100px;
$hero: 1760px;

// Transitions
$transition: all .3s ease-in-out;

// Breakpoints
$breakpoints: ("xs": 480px, "s": 576px, "m": 768px, "l": 992px, "xl": 1200px, "xxl": 1400px, "xxxl": 1680px, "xxxxl": 1800px);
