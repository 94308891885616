.block-employee {
    padding-top: 16rem;
    padding-bottom: 10.9rem;
    // padding-left: 11.6rem
    padding-right: 2*$base-width;
    padding-left: 2*$base-width;
    overflow: hidden;
    @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        padding-top: 12rem; }
    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        padding-left: 2*$base-width; }
    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-top: 10*$base-width;
        padding-bottom: 10*$base-width; }

    .employee_wrapper {
        // display: flex
        max-width: 1520px;
        margin: 0 auto;
        position: relative;
        z-index: 5;

        .employee-wrap {
            display: flex;
            z-index: 2;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                flex-wrap: wrap; } } }

    .bg_element-left {
        position: absolute;
        left: -3rem;
        top: -4rem;
        z-index: -1;
        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            top: 13rem; }
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            left: -20rem;
            top: 0; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            display: none; } }

    .bg_element-center {
        position: absolute;
        right: 46.6rem;
        top: -4rem;
        z-index: -1;
        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            top: 13rem; }
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            display: none; } }

    .bg_element-right {
        position: absolute;
        right: -3rem;
        top: -4rem;
        z-index: -1;
        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            top: 13rem; }
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            // top: 0
 }            // right: -20rem
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            display: none; } }

    .headline_vertical {
        top: 37.8rem;
        left: -20.2rem;
        @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
            top: unset;
            left: unset;
            position: relative;
            transform: unset;
            opacity: 1;
            padding-bottom: 15*$base-width;
            text-align: center;
            line-height: 1;
            font-family: $font-headline;
            font-size: 5rem;
            color: $dark;
            font-weight: 400; }
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            padding-bottom: 10*$base-width;
            font-size: 4rem; }
        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            padding-bottom: 5*$base-width; } }

    .employee-container {
        margin: 0;
        width: 100%;
        flex: 0 0 25%;
        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            // flex: 0 0 50%
            padding: 5*$base-width 2.5*$base-width; }
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex: 0 0 50%; }
        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            flex: 0 0 100%;
            padding: 5*$base-width 0; }


        p {
            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                word-break: break-word; } }

        .employee-picture-container {
            max-width: 35.6rem;
            width: 100%;
            margin: auto;
            max-height: 47.3rem;
            height: 100%;
            overflow: hidden;
            margin-bottom: 7.2rem;
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                max-height: 40rem;
                margin-bottom: 5rem; }

            img,picture {
                width: 100%;
                height: 100%;
                object-fit: cover; } }

        .owl-item {
            &.active {
                &:after {
                    content: "";
                    position: absolute;
                    max-width: 31rem;
                    max-height: 86rem;
                    height: 110%;
                    width: 100%;
                    background: $primary;
                    z-index: -1; } } }


        .employee-info-container {
            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                max-width: 35.6rem;
                width: 100%;
                margin: auto; }
            p {
                color: $dark;
                max-width: 35.7rem;
                width: 100%;
                margin-bottom: 0; }
            h3 {
                margin-bottom: 25px; } } }

    .owl-item {
        max-width: 35.6rem;
        width: 100%;
        margin-right: 4*$base-width; }
    .employee-slider {
        .owl-nav {
            display: flex;
            justify-content: center;
            padding-top: 6rem;
            column-gap: 6rem;
            z-index: 2;
            position: relative;


            button {
                max-width: 5rem;
                width: 100%;
                max-height: 5rem;
                height: 100%;
                &:hover {
                    path {
                        fill: $primary; }
                    #runter {
                        fill: $dark; } } }

            .owl-carousel-arrow {
                display: inline-block;
                max-width: 5rem;
                width: 100%;
                max-height: 5rem;
                height: 100%; } }

        .owl-dots {
            display: flex;
            justify-content: center;
            column-gap: 1rem;
            position: absolute;
            bottom: 1rem;
            left: 0px;
            width: 100%;

            button {
                width: 100%;
                max-width: 1.5rem;

                span {
                    max-width: 1.5rem;
                    width: 100%;
                    max-height: 1.5rem;
                    height: 100%;
                    display: inline-block;
                    background-color: $dark;
                    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%); } }

            .owl-dot {
                &.active {
                    span {
                        background-color: $primary; } } } } } }




